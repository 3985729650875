import React, { useState } from 'react';
import { PageProps, graphql } from 'gatsby';
import styled from 'styled-components';
import { PageInfoType } from '@/components/Page';
import { useEffect } from 'react';
import { generateInternalUrl } from '@/utils/SlugUtils';

const RedirectWrapper = styled.div`
  color: #333;
  font-size: 150%;
  font-weight: bold;
  padding: 10px;
  text-align: center;
`;

type ContentfulPageType = PageInfoType & {
  contentful_id: string;
  pageType: {
    __typename: string;
    contentful_id: string;
  };
};

type ContentfulContentType = {
  contentful_id: string;
};

type Props = {
  allContentfulPage: { nodes: [ContentfulPageType] };
  allContentfulHomepage: { nodes: [ContentfulPageType] };
  allContentfulBlogPageType: { nodes: [ContentfulContentType] };
  allContentfulArticle: { nodes: [ContentfulContentType] };
  allContentfulSupportArticle: { nodes: [ContentfulContentType] };
  allContentfulSupportHomepageType: { nodes: [ContentfulContentType] };
};

const RedirectPage: React.FC<PageProps<Props>> = ({ data }) => {
  // normal Pages
  const allPages = [
    ...data.allContentfulPage.nodes,
    ...data.allContentfulHomepage.nodes,
  ];

  // (Blog) Articles
  const allBlogArticles = [...data.allContentfulArticle.nodes];

  const allBlogPageTypes = [...data.allContentfulBlogPageType.nodes];

  // Support Articles
  const allSupportArticles = [...data.allContentfulSupportArticle.nodes];
  const allSupportHomepageTypes = [
    ...data.allContentfulSupportHomepageType.nodes,
  ];

  const [targetPath, setTargetPath] = useState(null);

  if (typeof window === 'undefined') {
    return null;
  }

  const { protocol, host } = window.location;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const contentfulID = params.get('id');
    const pageTypeID = params.get('pageTypeID');
    const locale = params.get('locale');

    // find a match on pageTypeID
    // we can only pass a contentful_id via the preview URL
    // with these results we know if we're handling a Support Article, (Blog) Article or normal Page
    const matchSupportArticle = allSupportArticles.find(
      (n) => n.contentful_id === pageTypeID && n.node_locale === locale,
    );
    const matchBlogArticle = allBlogArticles.find(
      (n) => n.contentful_id === pageTypeID && n.node_locale === locale,
    );

    // get the support homepage node & blog homepage node for our locale
    // we need to get the slug for them; but it's on the Page Content Type
    // -> so get the support homepage type node & blog type node
    const matchSupportHomepage = allSupportHomepageTypes.find(
      (n) => n.node_locale === locale,
    );
    const matchBlogHomepage = allBlogPageTypes.find(
      (n) => n.node_locale === locale,
    );

    // and loop over all pages and find a match for both results
    // now we have the pages and can access the slugs for both
    const genericSlugs = {};

    allPages.forEach((n) => {
      if (n.node_locale === locale && n.pageType) {
        if (n.pageType.contentful_id === matchSupportHomepage.contentful_id)
          genericSlugs.support = n.slug;
        if (n.pageType.contentful_id === matchBlogHomepage.contentful_id)
          genericSlugs.blog = n.slug;
      }
    });

    // ...or a normal Page
    const matchingPage = allPages.find((element) => {
      return (
        element.contentful_id === contentfulID && element.node_locale === locale
      );
    });

    // we'll need to build up the full path based on the page we want to redirect to
    // - normal Pages can be handled with the generateInternalUrl() fn
    // - Support Articles or (Blog) Articles need to be constructed with
    //   - the locale
    //   - the /support or /our-blog path
    //   - the Support Article slug or (Blog) Article slug
    const paths = [];
    if (matchingPage) {
      // add parent Page slug
      if (matchSupportArticle || matchBlogArticle) {
        // locale goes first
        paths.push(`/${locale}`);

        // for Support Articles or (Blog) Articles we need to get the parent slug
        if (matchSupportArticle) paths.push(genericSlugs.support);
        if (matchBlogArticle) paths.push(genericSlugs.blog);

        // add the final article slug
        paths.push(matchingPage.slug);
      } else {
        paths.push(generateInternalUrl(matchingPage));
      }

      const finalPath = paths.join('/');

      setTargetPath(finalPath);

      window.location.href = `${protocol}//${host}${finalPath}`;
    }
  }, [targetPath]);

  return (
    <RedirectWrapper>
      {targetPath ? (
        <h1>Redirecting you to {targetPath}</h1>
      ) : (
        <h2>
          Determining the redirect URL... this takes another minute.
          <br />
          <br />
          <pre>Contact Direct Channels in case of issues.</pre>
        </h2>
      )}
    </RedirectWrapper>
  );
};

export const query = graphql`
  query RedirectPageQuery {
    allContentfulPage {
      nodes {
        __typename
        contentful_id
        node_locale
        ...PagePathFragment
        pageType {
          ... on ContentfulArticle {
            __typename
            contentful_id
            title
          }
          ... on ContentfulSupportArticle {
            __typename
            contentful_id
            title
          }
          ... on ContentfulBlogPageType {
            __typename
            contentful_id
          }
          ... on ContentfulSupportHomepageType {
            __typename
            contentful_id
          }
        }
      }
    }
    allContentfulHomepage {
      nodes {
        __typename
        contentful_id
        node_locale
      }
    }
    allContentfulBlogPageType {
      nodes {
        __typename
        contentful_id
        node_locale
      }
    }
    allContentfulArticle {
      nodes {
        __typename
        contentful_id
        node_locale
      }
    }
    allContentfulSupportHomepageType {
      nodes {
        __typename
        contentful_id
        node_locale
      }
    }
    allContentfulSupportArticle {
      nodes {
        __typename
        contentful_id
        node_locale
      }
    }
  }
`;

export default RedirectPage;
